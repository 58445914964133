// import { createClient } from '../../prismicio';
// import { buildLottie } from '@utilities/buildLottie.node';

// export async function getStaticProps() {
//   const client = createClient();

//   const lottieFiles = await client.getAllByType('lottie_animation');

//   const data = lottieFiles.map((item) => ({
//     name: item.data.lottie_name[0].text,
//     url: item.data.lottie_file.url
//   }));

//   const output = data.map(({ name, url }) => buildLottie({ name, url }));
//   const logs = await Promise.all(output);

//   const svgData = logs.map((item) => item.data);

//   console.log({ svgData });

//   return {
//     // TODO: Add context or something similar so we can use these
//     // animations dynamically when pulling in content from the CMS.
//     props: { lottie: data.map(({ name }) => name) }
//   };
// }

import React from 'react';

import dynamic from 'next/dynamic';

import { Loading } from '@components/molecules/loading';

const CSRDashboard = dynamic(() => import('../csrPages/dashboard'), {
  loading: () => <Loading loading title="Loading" message="Building the page" />,
  ssr: false
});

const IndexPage = () => {
  return <CSRDashboard />;
};

export default IndexPage;
